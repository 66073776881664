import classnames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { FauxButton } from "./";
import { ButtonKind } from "./Button.component";
import "./Heading.style.scss";

export interface HeadingLink {
  text: string;
  url: string;
  label?: string;
}
type level = 1 | 2 | 3 | 4 | 5 | 6;

export interface HeadingProps {
  level?: level;
  hideOnMobile?: boolean;
  link?: HeadingLink;
  children?: React.ReactNode;
  className?: string;
}

export const Heading = (props: HeadingProps) => {
  const { t } = useTranslation();
  const seeAll = props.link ? (
    <FauxButton className="see-all" to={props.link.url} kind={ButtonKind.SECONDARY} size="small">
      {t("see_all")}
    </FauxButton>
  ) : null;
  const classes = classnames("component-heading", {
    responsive: props.hideOnMobile,
    [`${props.className}`]: props.className,
  });
  const heading = React.createElement(`h${props.level || 1}`, { className: "heading-title" }, props.children);
  return (
    <header className={classes}>
      {heading}
      {seeAll}
    </header>
  );
};

export default Heading;
